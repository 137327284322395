@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

@import '../style/shop.scss';
.random-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.random-list {
  max-height: 400px;
  overflow-y: scroll;
}
.item-index {
  margin-left: 10px;
  min-width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 2px solid #c5c0e0;
  color: #c5c0e0;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
}
